export default function useTTError({ friend = {}, tt }) {
    const { user = null } = friend;

    if (!user || !user.id) {
        return null;
    }

    const { types } = user;

    if (types) {
        return null;
    }

    return tt('no_tickettypes_for_friend', "No available ticket types for the user. Please check age.");
}