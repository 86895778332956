import React from "react";
import { useSelector } from "react-redux";

const messageForUser = ({ tt, user }) => {

    const { has_ticket, types } = user;

    if (!has_ticket && (!types || !!types?.length)) {
        return tt('imnotattending.no_avail_tickettypes', 'No available ticket types. Please check age.');
    }
    
    return tt('imnotattending.not_attending', 'You already have a ticket or you are not able to attend to this event.');
}

export default function OwnerError({ tt }) {
    const { loading, user, error } = useSelector(state => state.event.friends);

    if (loading || !user) {
        return null;
    }

    const message = messageForUser({user, tt});

    return (
        <div className='zt-p3' style={{textAlign: 'center', color: '#C00000'}}>{message}</div>
    );
}