import React, { useState, useCallback, useEffect } from 'react';
import DoYouHaveSwedishPersonalNumber, { TYPE_DONT_HAVEPERSONALNUMBER, TYPE_HAVEPERSONALNUMBER } from './components/DoYouHaveSwedishPersonalNumber';
import isCorrectPhone from 'pages/Event/pages/BuyTickets/AddFriends/Friend/components/PhoneSearch/isCorrectPhone';
import { PrimaryButton, SecondaryButton } from 'components/buttons/Button';
import useValidationErrors from './useValidationErrors';
import { path as parentPath } from 'pages/Registration';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'components/Inputs/PhoneInput';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'components/Inputs/DatePicker';
import useCreateUserError from './useCreateUserError';
import CheckBox from 'components/Inputs/CheckBox';
import DocumentId from './components/DocumentId';
import PersonalId from './components/PersonalId';
import countries_source from 'config/countries';
import { useTranslation } from 'react-i18next';
import Select from 'components/Inputs/Select';
import { useHistory } from 'react-router-dom';
import Input from 'components/Inputs/Input';
import * as formRules from 'config/forms';
import Personnummer from 'personnummer';
import flag from 'country-code-emoji';
import * as actions from './actions';
import Success from './Success';
import moment from 'moment';
import './style.scss';
import c from 'config';
import { Col, Row } from 'react-bootstrap';

export const path = '/document';
export const link = path;

const contries_iconed = Object.keys(countries_source).map( code => ({id:code, label:countries_source[code], icon: props => <span role="img" aria-label={code} {...props}>{flag(code)}</span> }));

const preffered = ['SE', 'NO', 'DK', 'FI'];
const COUNTRY_SWEDEN = 'SE';

const contries = []
preffered.forEach(id => {
    const c = contries_iconed.find( c => c.id === id);
    contries.push(c);
});
contries.push({});
contries_iconed.forEach(c => {
    if (!preffered.includes(c.id)) {
        contries.push(c);
    }
});

export default props => {
    const { t } = useTranslation('registration');

    const { loading, success, error } = useSelector(state => state.register.document);
    const errorMessage = useCreateUserError(t);
    
    const { email, phone } = useSelector(state => state.register.user);
    const history = useHistory();

    const { control, register, unregister, handleSubmit, watch, formState: { errors }, setValue, clearErrors, setError } = useForm({
        mode: 'onChange',
        validateCriteriaMode: "all"
    });

    const resetField = useCallback( name => {
        setValue(name, '', false);
        clearErrors(name);
    }, [setValue, clearErrors]);

    const dispatch = useDispatch();

    const [password, type] = watch(['password', 'password_confirm', 'agree', 'type'], false);

    const [havePN, setHavePN] = useState(TYPE_HAVEPERSONALNUMBER);

    const onSubmit = data => dispatch(actions.registerUser({...data, birthday: moment(data.birthday).format('YYYY-MM-DD')}));

    useEffect(() => {
        register('phone', {
            required: 'Required',
            minLength: {
               value: 7,
               message: t('common:.errors.validation.phone', 'Phone number is not correct')
            }
        });   
        register('country', {
            required: 'Required',
        });
        register('birthday', {
            required: 'Required',
        });        
    }, []);
      
    const country = watch('country', false);

    useValidationErrors(error, t, setError);

    useEffect(() => {
        setValue('document', '');
    }, [country]);

    useEffect(() => {
        if (havePN === TYPE_HAVEPERSONALNUMBER) {
            setValue('country', COUNTRY_SWEDEN);
        }
    }, [havePN]);

    useEffect(() => {
        
        if (!email || !phone) {
            history.push(parentPath);
        }

        setValue('phone', phone);

    }, [email, phone, history]);
    
    const document = watch('document', false);
    useEffect(() => {
        if (havePN !== TYPE_HAVEPERSONALNUMBER) {
            return;
        }
        if (!Personnummer.valid(document)) {
            return;
        }
        const prn = Personnummer.parse(document);
        setValue('birthday', Date.UTC(prn.fullYear, prn.month-1, prn.day));

    }, [document, havePN]);

    if (success) {
        return <Success t={t} />
    }

    return (
        <div className='zt-registration__document'>
            <div className='zt-registration__document__form'>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <h4 className='zt-registration__document__form__label'>{t('using_document.label', 'Registration with Passport or ID Card2')}</h4>
                        <div className='zt-registration__document__form__block'>
                            <div className='zt-registration__document__form__block__label'><h4>{t('using_document.account.label', 'Account')}</h4></div>
                            <div className='zt-registration__document__form__block__inputs'>
                                <Input 
                                    required
                                    {...register('email', {
                                        ...formRules.email,
                                        required: 'Required',
                                        pattern: {
                                            ...formRules.email.pattern,
                                            message: t('common:errors.validation.email', 'invalid email address')
                                    }})}                                     
                                    defaultValue={email}
                                    label={t('using_document.account.inputs.email.label', 'Email (will used as login):')}
                                    placeholder={t('using_document.account.inputs.email.placeholder','Enter a valid Email')}
                                    onClear={() => resetField('email')}
                                    error={errors?.email && (errors.email.message || t('common:errors.validation.email', 'invalid email address'))}
                                />
                                <Input
                                    required
                                    type="password"
                                    {...register('password', {
                                        ...formRules.password,
                                        required: 'Required',
                                    })}                                    
                                    label={t('using_document.account.inputs.password.label', 'Password:')}
                                    placeholder={t('using_document.account.inputs.password.placeholder', 'Create a password')}
                                    onClear={() => resetField('password')}
                                    error={errors?.password && (errors.password.message || t('common:errors.validation.password', 'invalid password'))}
                                />
                                <Input
                                    required                                 
                                    type="password"
                                    {...register('password_confirm', {
                                        ...formRules.password,
                                        required: 'Required',
                                        validate: value => value === password,
                                    })}                                    
                                    label={t('using_document.account.inputs.password_confirm.label', 'Confirm password:')}
                                    placeholder={t('using_document.account.inputs.password_confirm.placeholder', 'Confirm a password')}
                                    onClear={() => resetField('password_confirm')}
                                    error={errors?.password_confirm && (errors.password_confirm.message || t('common:errors.validation.password_confirm', 'Password not match'))}
                                />
                            </div>
                        </div>
                        <div className='zt-registration__document__form__block'>
                            <div className='zt-registration__document__form__block__label'><h4>{t('using_document.profile.label', 'Profile')}</h4></div>
                            <div className='zt-registration__document__form__block__inputs'>
                                <Input
                                    {...register('given_name', {
                                        ...formRules.name,
                                        required: 'Required',
                                    })}  
                                    label={t('using_document.profile.inputs.first_name.label', 'First name:')}
                                    placeholder={t('using_document.profile.inputs.first_name.placeholder', 'Enter real Name')}
                                    onClear={() => resetField('first_name')}
                                    error={errors?.first_name?.message}
                                />
                                <Input
                                    {...register('family_name', {
                                        ...formRules.lastName,
                                        required: 'Required',
                                    })} 
                                    label={t('using_document.profile.inputs.last_name.label', 'Last name:')}
                                    placeholder={t('using_document.profile.inputs.last_name.placeholder', 'Enter real Last name')}
                                    onClear={() => resetField('last_name')}
                                    error={errors?.last_name?.message}                                    
                                />
                                <Controller
                                    control={control}
                                    name='phone'
                                    rules={{
                                        required: 'Required',
                                        minLength: 6,
                                        validate: value => isCorrectPhone(value) ? true : t('common:errors.validation.phone', 'invalid phone format')
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => 
                                        <PhoneInput 
                                            name='phone'
                                            placeholder={t('using_document.profile.inputs.phone.placeholder', 'Type your phone here')}
                                            label={t('using_document.profile.inputs.phone.label', 'Phone number:')}
                                            search={t('using_document.profile.inputs.phone.search', 'Search')}
                                            onClear={() => resetField('phone')}
                                            {...{onChange, onBlur, value}}
                                            error={errors?.phone?.message}
                                            defaultValue={phone}
                                            autoFocus={false}
                                        />                                        

                                    }
                                />
                            </div>                    
                        </div>      
                        <DoYouHaveSwedishPersonalNumber {...{t, havePN, setHavePN }} />
                        {havePN !== TYPE_HAVEPERSONALNUMBER && (
                        <div className='zt-registration__document__form__block'>
                            <div className='zt-registration__document__form__block__label'><h4>{t('using_document.profile.inputs.birthdate.label', 'Date of birth')}</h4></div>
                            <div className='zt-registration__document__form__block__inputs'>
                                <DatePicker
                                    name='birthday'
                                    onChange={date => setValue('birthday', date)}                           
                                    error={errors?.birthday?.message}
                                />
                            </div>
                        </div>)}
                        {{
                            [TYPE_HAVEPERSONALNUMBER]: <PersonalId {...{t, control, errors, register, resetField }} />
                        }[havePN] || <DocumentId {...{t, /*type,*/ errors, /*onChange: value => setValue('type', value),*/ register, unregister, resetField}} />}
                        {havePN == TYPE_DONT_HAVEPERSONALNUMBER && <div className='zt-select__nationality'>
                            <Row>
                            <Col xs={12} sm={6} className=''>
                            <Select
                                label={t('using_document.profile.inputs.country.label', 'Country:')}
                                placeholder={t('using_document.profile.inputs.country.placeholder', 'Select country')}
                                searchplaceholder={t('using_document.profile.inputs.country.search', 'Search country')}
                                items={contries}
                                error={errors?.country?.message}
                                onChange={value => setValue('country', value, {shouldValidate: true})}
                            />
                            </Col>
                            </Row>
                        </div>}                      
                        <div className='zt-registration__document__form__block'>
                            <PrimaryButton
                                error={errorMessage}
                                onClick={handleSubmit(onSubmit)}    
                                disabled={loading}
                                loading={loading}
                                className='zt-registration__document__form__submitbutton'
                                >
                                    {t('using_document.buttons.subbmit', 'Create Account')}
                            </PrimaryButton>
                        </div>
                </form>
            </div>

        </div>
    );
}
