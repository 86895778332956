import React, { useEffect, useState } from 'react';
import Samples, { MODE_INTERNETIONAL } from './components/Samples';
import Info, { TYPE_ID_CARD, TYPE_PASSPORT } from './components/Info';
import Input from 'components/Inputs/Input';
import { Col, Row } from 'react-bootstrap';


export default ({ t, errors, register, unregister, resetField }) => {

    const [type, setType] = useState(TYPE_PASSPORT);

    return (
        <div className='zt-registration__document__form__block'>
            <div className='zt-registration__document__form__block__label'>
                <h4>{t('using_document.document.label', 'Document which you\'ll use as a Ticket')}</h4>
            </div>
            <Info {...{mode: MODE_INTERNETIONAL, t, type, setType}} />
            <Row>
                <Col xs={12} sm={6} className=''>
                    <Input 
                        {...register('document', {required: 'Required', validate: value => true})}
                        label={t(`using_document.document.inputs.document.${type}.label`, 'Document number:')}
                        placeholder={t(`using_document.document.inputs.document.${type}.placeholder`, 'Enter document number')}
                        onClear={() => resetField('document')}
                        error={errors?.document?.message}    
                    />                
                </Col>
            </Row>
        </div>      
    )
}