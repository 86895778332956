import React, { useCallback, useRef, useEffect, useState } from 'react';
import { UserIcon, QR as QRIcon, Price as PriceIcon, ArrowRight } from 'components/Icons/Glyphs';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import useAlreadySelectedPhones from './useAlreadySelectedPhones';
import FriendTicketType from './components/FriendTicketType';
import { PrimaryButton } from 'components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import useIsAllPricesSet from '../useIsAllPricesSet';
import PhoneSearch from './components/PhoneSearch';
import { Row, Col, Button } from 'react-bootstrap';
import CheckBox from 'components/Inputs/CheckBox';
import FriendError from './components/FriendError';
import { useTranslation } from 'react-i18next';
import Input from 'components/Inputs/Input';
import useFriendMode from './useFriendMode';
import { useForm } from 'react-hook-form';
import * as formRules from 'config/forms';
import Divider from 'components/Divider';
import InfoElement from '../InfoElement';
import * as actions from './../actions';
import useTTError from './useTTError';
import PayMode from './PayMode';
import FRIENDMODE from './mode';

const Controlls = ({ last, checkout, ref, subbmit, isValid, t, allPriceSelected }) => {
    if (checkout) {
        return (
        <div className='zt-event-buy__add-friends__controlls'>
            <Row>
                <Col md={{ span: 3, offset: 9 }}>
                    <PrimaryButton ref={ref} disabled={!isValid() || !allPriceSelected} className='zt-event-buy__add-friends__friend__form__controlls__addbutton' onClick={subbmit}>{t('buttons.checkout', 'Checkout')} <ArrowRight /></PrimaryButton>
                </Col>
            </Row>
        </div>
        );
    }

    if (last) {
        return (
        <div className='zt-event-buy__add-friends__controlls'>
            <Row>
                <Col md={{ span: 3, offset: 9 }}>
                    <PrimaryButton ref={ref} disabled={!isValid()} className='zt-event-buy__add-friends__friend__form__controlls__addbutton' icon={UserIcon} onClick={subbmit}>{t('buttons.add_friend', 'Add Friend')}</PrimaryButton>
                </Col>
            </Row>
        </div>            
        )
    }

    return null;
}


export default ({ friend, iamnotattending, kidsAllowed = false, last = false, checkout, dispatcher = null, props }) => {
    
    const nokid = !kidsAllowed;
    const ref = useRef(); //Button
    const { user = null, searching: loading } = friend;
    const inc = iamnotattending ? 1 : 2;
    const number = friend.id + inc;

    const [phone, setPhone] = useState('');
    
    const { t } = useTranslation('buying');
    const tt = (code, def) => t(`add_friends.${code}`, def)

    const dispatch = useDispatch();

    const resTime = useSelector(state => state.event.friends.reservedTime);

    const allPriceSelected = useIsAllPricesSet();

    const { register, watch, handleSubmit, setValue, clearErrors, formState, setError, formState: { errors }, setFocus } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });

    const resetField = useCallback( name => {
        setValue(name, '', { shouldDirty: true });
        clearErrors(name);
    }, [setValue, clearErrors]);

    const [ first_name, last_name, paytype ] = watch(['first_name', 'last_name', 'paytype'], false);

    const onSubmit = data => {

        const { first_name, last_name, paytype } = data;

        if ([FRIENDMODE.NEWUSER, FRIENDMODE.KID].includes(mode)) {
            dispatch(actions.updateFriendName(friend.id, {first_name, last_name, phone}));
        }
        if (mode === FRIENDMODE.EXISTED) {
            dispatch(actions.updateFriendName(friend.id, {phone}));
            dispatch(actions.updateFriendPay(friend.id, paytype));
        }

        if (checkout) {
            checkout();            
        } else {
            dispatch(actions.addFriend());
        }
    }
    
    
    const mode = useFriendMode(friend);
   
    const isValid = () => friend?.ticket.type && (!!+friend?.ticket.price || friend?.ticket.price === 0);
    
    const sentMessage = tt('sentMessage', 'A notification will be sent to your friend');
    const reservedMessage = tt('reservedMessage', {time: resTime}, `The ticket will be reserved for ${resTime} min.`);

    const entername = mode === FRIENDMODE.NEWUSER;

    const alreadySelectedPhones = useAlreadySelectedPhones(user?.phone);

    useEffect(() => {
        if (ref?.current && !loading) {
            switch(mode) {
                case FRIENDMODE.EXISTED: {
                    ref.current.focus();
                    ref.current.blur();
                    return;
                }
                case FRIENDMODE.KID: {
                    setFocus('first_name');
                    return;
                }
                case FRIENDMODE.NEWUSER: {
                    setFocus('first_name');
                    return;
                }
                default: {
                    return;
                }
            }
        }

    }, [loading, mode]);
    
    useEffect(() => {
        dispatch(actions.updateFriendPay(friend.id, paytype));
    }, [dispatch, friend.id, paytype]);

    const subbmit = useCallback(handleSubmit(onSubmit), [mode]);

    useEffect(() => {
        if (last) {
            return;
        }

        switch(mode) {
            case FRIENDMODE.KID:
            case FRIENDMODE.NEWUSER: {
                if (formState.isValid && (user.first_name !== first_name || user.last_name !== last_name)) {
                    dispatch(actions.updateFriendName(friend.id, {first_name, last_name, phone}));
                } else {
                    //here to empty the invalid data from the state:
                }
                return;
            }            
            default: {
                return;
            }
        }

    }, [first_name, last_name, phone, mode, formState.isValid, subbmit, user, friend.id, dispatch]);

    useEffect(() => {
        register('paytype', {
            required: 'Required',
        });
    }, []);

    useEffect(() => {
        if (!last || !dispatcher) {
            return;
        }
        dispatcher.subscribe(() => subbmit());
        return () => dispatcher.unsubscribe();
    }, [subbmit]);

    const noAvailTTsError = useTTError({friend, tt});

    return ( 
    <div>
    <div className='zt-event-buy__add-friends__friend'>
        <Divider />
        <form onSubmit={() => console.log('SUBBMIT')}>
            <div className='zt-event-buy__add-friends__friend__form'>
                <Row>
                    <Col md={5}>
                        <div className='zt-event-buy__add-friends__friend__form__header'>
                            <div className='zt-event-buy__add-friends__myticket__number'>{number}. {/* mode */}</div> 
                            {friend.searching ?
                                <InfoElement bold icon={MiniLoader} className='zt-event-buy__add-friends__myticket__label'></InfoElement>
                                :
                                !friend.kid && (user?.first_name || user?.last_name) && <InfoElement bold icon={UserIcon} className='zt-event-buy__add-friends__myticket__label'>{`${user?.first_name} ${user?.last_name}`}</InfoElement>
                            }     
                        </div>
                        <FriendError {...{error: noAvailTTsError, tt}} />
                    </Col>
                    <Col md={4}>
                        { !loading && ![FRIENDMODE.UNSET, FRIENDMODE.ERROR].includes(mode) &&
                        <FriendTicketType {...{t, friend, nokid, disabled: !!noAvailTTsError}} /> }                      
                    </Col>
                    <Col md={3}>
                        { !nokid &&
                            <CheckBox className='zt-event-buy__add-friends__friend__form__header__checkbox'
                                name="child"
                                disabled={[FRIENDMODE.LOADING, FRIENDMODE.EXISTED].includes(mode)}
                                onChange={e => dispatch(actions.setKid(friend.id, e.target.checked))}
                                >
                                {tt('inputs.kids_ticket', 'Kid\'s Ticket')}
                            </CheckBox>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <div className='zt-event-buy__add-friends__friend__form__inputs'>
                        { !friend?.kid &&
                            <PhoneSearch {...{tt, 
                                    entername, 
                                    friend, 
                                    last,
                                    loading: false, 
                                    onEnter: phone => {setPhone(phone);dispatch(actions.searchFriend(friend.id, phone))},
                                    onClear: () => dispatch(actions.resetFriend(friend?.id)),
                                    description: entername ? null : sentMessage,
                                    usedPhones: alreadySelectedPhones,
                                    subDescription: entername ? null : reservedMessage
                                }} />
                        }
                        {(entername || friend?.kid) &&
                        <>
                            <Input
                                {...register('first_name', {
                                    ...formRules.name,
                                    required: 'Required',
                                })}
                                defaultValue={user?.first_name}                               
                                label={tt('inputs.first_name', 'First name:')}
                                onClear={() => resetField('first_name')}
                            />
                            <Input
                                {...register('last_name', {
                                    ...formRules.lastName,
                                    required: 'Required',
                                })}                                 
                                defaultValue={user?.last_name}                               
                                label={tt('inputs.last_name', 'Last name:')} 
                                onClear={() => resetField('last_name')}
                                description={friend?.kid ? null : sentMessage}
                                description2={friend?.kid ? null : reservedMessage}                   
                            />
                        </>
                        }
                        </div>
                    </Col>
                    <Col md={{span: 3, offset: 4}} className='zt-event-buy__add-friends__friend__form__controlls'>
                        <PayMode {...{setValue, mode, friend,tt}} />
                    </Col>
                </Row>
            </div>
        </form>
    </div>
    <Controlls {...{last, checkout, ref, subbmit, isValid, t, allPriceSelected}} />
    </div>
    );
}