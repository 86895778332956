import React from 'react';
import Input from 'components/Inputs/Input';
import Personnummer from 'personnummer';
import { Controller } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';

let lastValue = '';

function adoptPersonalNumber(value) {
    
    const currentYear = +(new Date()).getFullYear().toString().substr(-2);
    const deleting = +value?.length < +lastValue?.length;

    lastValue = value;

    if (value?.length !== 2 || [19,20].includes(+value) || deleting) {
        return value
    }
    const year = +value || 0;
    return currentYear-year < 10 ? `19${value}` : `20${value}`;
}

export default ({ t, register, errors, resetField, control, ...props }) => {

    return (
        <div className='zt-registration__document__form__block'>
            <div className='zt-registration__document__form__block__label'>
                <h4>{t('using_document.personal_id.label', 'Enter your Personal ID')}</h4>
                <div>
                    <p>{t('using_document.personal_id.description', 'You will be able to use your Swedish driver’s license, Swedish national ID-card or Swedish passport as a ticket.')}</p>
                </div>
            </div>
            <Row>
                <Col xs={12} sm={6} className=''>
                    <Controller 
                        control={control}
                        name="document"
                        rules={{required: 'Required', validate: value => (Personnummer.valid(value) && value.length === 12) || t('using_document.personal_id.input.error', 'Entered number is not correct')}}
                        render={({ field: { value }, field, ...props }) => {
                            return <Input 
                                {...field}
                                {...{value: value || ''}}
                                label={t('using_document.personal_id.input.label', 'Personal ID number:')}
                                placeholder={t('using_document.personal_id.input.placeholder', 'Enter a valid Personal ID number')}
                                onClear={() => resetField('document')}
                                error={errors?.document?.message}  
                                onChange={(e) => field.onChange(adoptPersonalNumber(e.target.value))}  
                            />}}         
                    />
                </Col>
            </Row>
        </div>
    );
}