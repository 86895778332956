import React from 'react';
import CheckBox from 'components/Inputs/CheckBox';
import './style.scss';

const Agree = ({ t, ...props }) => <div className='zt-registration__selectmethod__agee'>
    <div className='zt-registration__selectmethod__agee__block'>
        <CheckBox {...props}>
            {t('select_method.accept', 'I accept Terms and Conditions and Data Privacy Policy')}
        </CheckBox>    
    </div>
</div>


export default Agree;