import RadioGroup from "components/Inputs/RadioGroup";
import React, { useState } from "react";

export const TYPE_HAVEPERSONALNUMBER = 'yes';
export const TYPE_DONT_HAVEPERSONALNUMBER = 'no';


export default function DoYouHaveSwedishPersonalNumber({ t, havePN, setHavePN }) {
    
    const items = [TYPE_HAVEPERSONALNUMBER, TYPE_DONT_HAVEPERSONALNUMBER].map(key => ({id: key, label: t(`using_document.have_personal_number.inputs.${key}`, key)}));

    return (
        <div class="zt-registration__document__form__block">
            <div className='zt-registration__document__form__block__label'>
                <h4>{t('using_document.have_personal_number.label', 'Do you have a Swedish personal number?')}</h4>
            </div>        
            <RadioGroup
                required
                name='have_pn'                                    
                defaultValue={havePN}
                onChange={value => setHavePN(value)}
                items={items} />
        </div>
    );
}